import gql from 'graphql-tag';

export const CONNECT_CARD = gql`
    mutation myConnectCustomerLoyaltyCard($cardId: String!, $billId: String!, $iagree: Boolean!) {
        connectCustomerLoyaltyCard(cardId: $cardId, billId: $billId, iagree: $iagree) {
            message
        }
    }
`;

export const GET_CARD = gql`
    {
        historyCustomerLoyaltyCard {
            message
            success
            loyalty {
                memberID
                cardID
                fullCardId
                saldoPoints
                isActive
                store
                transID
                transDate
                description
                income
                expense
            }
        }
    }
`;

export const CREATE_CARD = gql`
    mutation createCard(
        $firstname: String!
        $lastname: String!
        $street: String!
        $streetNumber: String!
        $zip: String!
        $city: String!
        $country: String!
        $telephone: String!
        $isMobile: Boolean!
        $gender: String!
        $email: String!
        $dob: String!
    ) {
        createCustomerLoyaltyCard(
            firstname: $firstname
            lastname: $lastname
            street: $street
            streetNumber: $streetNumber
            zip: $zip
            city: $city
            country: $country
            telephone: $telephone
            isMobile: $isMobile
            gender: $gender
            email: $email
            dob: $dob
        ) {
            message
        }
    }
`;

export const ISSUE_TOKEN = gql`
    {
        getLoyaltyToken {
            success
            message
            token {
                webSiteCode
                memberID
                memberToken
                validTo
                errorMsg
            }
        }
    }
`;
