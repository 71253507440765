import React from 'react';
import './BaseComponent.scss';
import NobilnaKuhinjeHr from '../../../../public/images/kitchenComposition/Bazna_kompozicija_HR_NOBILIA_KUHINJE.png';
import NobilnaKuhinjeRs from '../../../../public/images/kitchenComposition/Bazna_kompozicija_RS_NOBILIA_KUHINJE.png';
import NolteKuhinjeHr from '../../../../public/images/kitchenComposition/Bazna_kompozicija_HR_NOLTE_KUHINJE.jpg';
import NolteKuhinjeRs from '../../../../public/images/kitchenComposition/Bazna_kompozicija_RS_NOLTE_KUHINJE.jpg';
import AranKuhinje from '../../../../public/images/kitchenComposition/Bazna_kompozicija_HR_ARAN_KUHINJE.png';


function BaseComponent(props) {
    let image = '';
    if (props.vendor_id === '27293') {
        image = NobilnaKuhinjeHr;
    } else if (props.vendor_id === '183812') {
        image = NolteKuhinjeHr;
    } else if (props.vendor_id === '16922') {
        image = NolteKuhinjeRs;
    } else if (props.vendor_id === '027293') {
        image = NobilnaKuhinjeHr;
    } else if (props.vendor_id === '3613') {
        image = NobilnaKuhinjeRs;
    } else if (props.vendor_id === '601586') {
        image = AranKuhinje;
    }
    return (
        <div>
            <div onClick={props.closeComponent} className="background"/>
            <div className="baseComponentContent">
                <h2 className="title">
                    Bazna kompozicija
                </h2>
                <img src={image} alt=""/>
                <div onClick={props.closeComponent} className="closeButton"/>
            </div>
        </div>
    )
}

export default BaseComponent;
