import PropTypes from 'prop-types';
import React, {memo, useEffect} from 'react';
import './AddProductInput.scss';
import {GET_CARD} from '../../PersonalArea/PersonalAreaClub/queriesClub';
import {useQuery} from '@apollo/react-hooks';
import {useUserContext} from "../../../context/UserContext";
import Prices from '../../BaseCurrencyPrices/Prices';

const countDecimals = (number) => {
    let numberString = number.toString();
    if (numberString.indexOf('.') !== -1) {
        let dotIndex = numberString.indexOf('.');

        return numberString.slice(dotIndex + 1).length;
    } else {
        return 0;
    }
}
const AddProductInput = ({ minSaleQty, quantity, setQuantity, advancedInventory, prices }) => {
    const decimalPlaces = countDecimals(minSaleQty)
    const {user} = useUserContext();
    let allPrices = [];
    let bestPrice = 0;
    let unit = '';

    const updateQuantity = (qty) => {
        setQuantity(parseFloat(qty.toFixed(decimalPlaces)))
    }
    // Fetch card but don't when no user data
    const {data: card} = useQuery(GET_CARD, {
        fetchPolicy: 'no-cache',
        skip: !user
    });

    if (advancedInventory && advancedInventory.enable_qty_increments === '1') {
        unit = advancedInventory.unit;
        if (unit && unit.charAt(0) === '/') {
            unit = unit.substring(1);
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].price_type === 'simple_loyalty' &&
                card &&
                card.historyCustomerLoyaltyCard &&
                card.historyCustomerLoyaltyCard.loyalty &&
                card.historyCustomerLoyaltyCard.loyalty.length !== 0 &&
                card.historyCustomerLoyaltyCard.loyalty[0].isActive
            ) {
                allPrices.push(Number(prices[i].amount));
            }
            if (prices[i].price_type !== 'simple_loyalty') {
                allPrices.push(Number(prices[i].amount));
            }

        }
        bestPrice = Math.min(...allPrices);
    }

    

    let enableQtyIncrements = '0';
    let qtyIncrements = 0;

    const packageQuantity = parseInt((quantity / minSaleQty).toFixed(0))
    const totalQuantityAmount = (bestPrice * quantity).toFixed(2);

    if (typeof advancedInventory !== 'undefined' && advancedInventory.hasOwnProperty('enable_qty_increments')) {
        enableQtyIncrements = advancedInventory.enable_qty_increments;
    }

    function decrement() {
        if (enableQtyIncrements === '0') {
            if (quantity > 1) {
                updateQuantity(quantity - 1);
            }
        } else {
            if (packageQuantity > 1) {
                updateQuantity((packageQuantity - 1) * minSaleQty)
            }
        }
    }

    function increment() {
        if (enableQtyIncrements === '0') {
            updateQuantity(quantity + 1)
        } else {
            updateQuantity((packageQuantity + 1) * minSaleQty)
        }
    }

  return (
      <>
        {enableQtyIncrements === '0' &&

            <div className="sidebar-add-product-quantity">
              <button
                className="sidebar-add-product-quantity__button sidebar-add-product-quantity__button_dec"
                onClick={decrement}
              >
                -
              </button>
              <input type="number" className="sidebar-add-product-quantity__input" disabled value={quantity} />
              <button
                className="sidebar-add-product-quantity__button sidebar-add-product-quantity__button_inc"
                onClick={increment}
              >
                +
              </button>
            </div>

        }
        {advancedInventory && enableQtyIncrements === '1' &&
            <div className="sidebar-add-product-quantity-decimal">
                <div style={{display:"flex"}}>
                    <input type="number" className="sidebar-add-product-quantity-decimal__inputDecimalQuantity" disabled value={quantity} />
                    <input type="text" className="sidebar-add-product-quantity-decimal__inputUnit" value={unit} disabled />
                    <p className="sidebar-add-product-quantity-decimal__equally">=</p>
                </div>
                <div style={{display:"flex"}}>
                    <button
                        className="sidebar-add-product-quantity-decimal__button sidebar-add-product-quantity-decimal__button_dec"
                        onClick={decrement}
                    >
                        -
                    </button>

                    <input type="number" className="sidebar-add-product-quantity-decimal__input" disabled
                           value={packageQuantity}/>
                    <input type="text" className="sidebar-add-product-quantity-decimal__inputPackage" value="paket"
                           disabled/>
                    <button
                        className="sidebar-add-product-quantity-decimal__button sidebar-add-product-quantity-decimal__button_inc"
                        onClick={increment}
                    >
                        +
                    </button>
                    <p className="sidebar-add-product-quantity-decimal__equally">=</p>
                    <div className="sidebar-add-product-quantity-decimal__totalPriceDesktop">
                        <p className="sidebar-add-product-quantity-decimal__totalPrice">
                            <Prices amount={totalQuantityAmount} />
                        </p>
                    </div>
                </div>
                <div className="sidebar-add-product-quantity-decimal__totalPriceMobile">
                    <p className="sidebar-add-product-quantity-decimal__totalPrice">
                        <Prices amount={totalQuantityAmount} />
                    </p>
                </div>
            </div>
        }
        </>
  );
};


AddProductInput.proptypes = {
    quantity: PropTypes.number,
    setQuantity: PropTypes.func,
    minSaleQty: PropTypes.number,
};

export default AddProductInput;
