import React, { memo } from 'react';
import { T } from '@deity-io/falcon-i18n';
import buyIcon from '../../../assets/img/nazovi-kupi-icon-hr.webp';
import './ProductSidebarPhoneOrder.scss';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';

const phoneSupportNumber = readConfig(productionConfiguration, 'phoneSupportNumber');

const ProductSidebarPhoneOrder = () => {
  // TODO: translation
  return (
    <div className="sidebar-phone-order">
      <a href={`tel:${phoneSupportNumber}`}>
        <img loading="lazy" className="sidebar-phone-order__img" src={buyIcon} alt="" />
      </a>
      <div className="sidebar-phone-order__text">
        <T id="product.checkCallPrice" />
        <span className="sidebar-phone-order__tooltip-open">
          &nbsp;
          <T id="product.here" />.
        </span>
        <div className="sidebar-phone-order__tooltip">
          <T id="product.callPrice" />
          <br />
          <b>
            <T id="product.note" />
          </b>
          <T id="product.callPriceNote" />
        </div>
      </div>
    </div>
  );
};

export default memo(ProductSidebarPhoneOrder);
