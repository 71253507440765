import React from 'react';
import InfoIcon from "../../../icons/InfoIcon";
import './PriceInfo.scss'

const PriceInfo = ({product, ...props}) => {

    const infos = product.prices.filter((price) => price.description.length > 0);


    if (infos.length == 0) {
        return false;
    }

    return (
        <div className='price-info'>
            {
                infos.map((price, index) => {
                    return (
                        <div className='item' key={index}>
                            <div><InfoIcon className={'price_description_icon'} fill={price.bg_color}/></div>
                            <div className='infos'>
                                <span className='info_head' style={{}}>{price.label}</span>
                                {price.description.map((description, index) => {
                                    return (
                                        <span key={index}>{description} </span>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })
            }
            {props.children}
        </div>
    )
}

export default PriceInfo