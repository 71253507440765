import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity-io/falcon-i18n';
import PropTypes from 'prop-types';
import './ProductSidebarQuestins.scss';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';

const phoneSupportNumber = readConfig(productionConfiguration, 'phoneSupportNumber');
const countryCode = readConfig(productionConfiguration, 'countryCode');

const ProductSidebarQuestions = ({ sku, name }) => {
  return (
    <div className="sidebar-questions">
      <div>
        <T id="product.haveQuestion" />
          {countryCode === 'hr-HR' && (
          <div>
              {' '}
              <T id="product.askQuestion"/>
                &nbsp;
              <Link
                to={`/emmezeta-pitanja-o-proizvodu?productSku=${sku}&productName=${name}`}
                className="sidebar-questions__link"
              >
                <T id="product.online" />
              </Link>
          </div>
          )}
      </div>
      <br />
        {countryCode === 'hr-HR' && (
            <div>
                <T id="product.checkAvailability" />
                <b>
                    &nbsp;
                    <T id="product.ourStores" />
                    &nbsp;
                </b>
                <T id="product.callNumber" /> <a className="sidebar-questions__link__number" href={`tel:${phoneSupportNumber}`}>{phoneSupportNumber}</a>.
                <br/>
                <strong><small><T id="product.callCenterOpeningHours" /></small></strong>
            </div>
        )}
        {countryCode === 'sr-RS' && (
            <div>
                <T id="product.askQuestion"/>
                {' '}
                <Link
                    to={`/emmezeta-pitanja-o-proizvodu?productSku=${sku}&productName=${name}`}
                    className="sidebar-questions__link"
                >
                    <T id="product.online" />
                </Link>
                {' '}
                <T id="product.OrCall" />
                {' '}
                <a className="sidebar-questions__link__number" href={`tel:${phoneSupportNumber}`}>{phoneSupportNumber}</a>.
                <br />
                <small><T id="product.callPriceSideBar" /></small>
            </div>
        )}
    </div>
  );
};

ProductSidebarQuestions.propTypes = {
  sku: PropTypes.string,
  name: PropTypes.string,
};

export default memo(ProductSidebarQuestions);
