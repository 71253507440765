import { isHR } from './isHr';

const getPercentage = (amount, last30) => {
  return Math.floor((last30 - amount) / last30 * 100);
};

export const isValidPromoOrClearance = (amount, last30) => {
  return !isHR || !last30 || getPercentage(amount, last30) >= 0;
}

export const hasValidSimplePromo = (product) => {
  const promoPrice = product.prices.find(price => price.price_type === 'simple_promo');
  if (!promoPrice) {
    return false;
  }

  return !isHR || !product.price_last_30 || getPercentage(promoPrice.amount, product.price_last_30) >= 1;
}

export const hasValidSimpleClearance = (product) => {
  const clearancePrice = product.prices.find(price => price.price_type === 'simple_clearance');
  if (!clearancePrice || hasValidSimplePromo(product)) {
    return false;
  }

  return !isHR || !product.price_last_30 ||
      getPercentage(clearancePrice.amount, product.price_last_30) >= 1 ||
      getPercentage(clearancePrice.amount, product.price_last_30) < 0;
}

export const hasValidGroupedPromo = (product) => {
  return product.items && product.items.some(item => item.product && hasValidSimplePromo(item.product));
}

export const hasValidGroupedClearance = (product) => {
  return product.items && product.items.some(item => item.product && hasValidSimpleClearance(item.product));
}
