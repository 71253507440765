/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {T} from '@deity-io/falcon-i18n';
import PropTypes from 'prop-types';
import React, {memo, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import useResponsive from '../../../hooks/useResponsive';
import ProductSidebarAddGroupedProduct from '../ProductSidebarAddGroupedProduct/ProductSidebarAddGroupedProduct';
import ProductSidebarAddSimpleProduct from '../ProductSidebarAddSimpleProduct/ProductSidebarAddSimpleProduct';
import ProductSidebarClub from '../ProductSidebarClub/ProductSidebarClub';
import ProductSidebarDelivery from '../ProductSidebarDelivery/ProductSidebarDelivery';
import ProductSidebarGroupedPromos from '../ProductSidebarGroupedPromos/ProductSidebarGroupedPromos';
import ProductSidebarPhoneOrder from '../ProductSidebarPhoneOrder/ProductSidebarPhoneOrder';
import ProductSidebarQuestions from '../ProductSidebarQuestions/ProductSidebarQuestions';
import ProductSidebarShortDescription from '../ProductSIdebarShortDescription/ProductSidebarShortDescription';
import ProductSidebarWarranty from '../ProductSidebarWarranty/ProductSidebarWarranty';
import {isHR} from '../../../utils/isHr';
import './ProductSidebar.scss';
import PriceBlock from "../PriceBlock/PriceBlock";
import {useUserContext} from "../../../context/UserContext";
import PriceInfo from "../PriceInfo/PriceInfo";
import {BuildAbsoluteUrl} from "../../../utils/urlBuilder";

const ProductSidebar = ({product, setTab}) => {
    const [waste, setWaste] = useState(false);

    let _product = product;
    if (product.__typename === 'GroupedProduct') {
        _product = product.items[0].product;
    }

    const [currentProduct, setCurrentProduct] = useState(_product);
    const {isMobile} = useResponsive();
    const history = useHistory();
    const {user} = useUserContext();
    const {
        uid,
        sku,
        prices,
        guarantees,
        name,
        swatch_attribute_images,
        categories,
        is_on_order_only,
        days_to_delivery,
        is_saleable
    } = product || {};

    const breadcrumbs = categories && categories[categories.length - 1];
    const {category_id} = (breadcrumbs && breadcrumbs.breadcrumbs[0]) || {};
    const url = BuildAbsoluteUrl(product.canonical_url);

    const subscribeToAlert = (uid) => {
        const encodedUID = atob(uid);
        const redirectPath = `/productalert/add/${encodedUID}${history.location.pathname}`;

        // Have to redirect here so that we get a clean history
        if (!user) {
            history.push(`/customer/login?referrer=${redirectPath}`);
            return;
        }
        history.push(redirectPath);
    };

    // Check for EEwaste (it only shows on this two category_ids)
    useEffect(() => {
        if (breadcrumbs && category_id) {
            if (category_id === 374 || category_id === 375) {
                setWaste(true);
            }
        }
    }, []);

    useEffect(() => {
        if (product) {
            setCurrentProduct(product)
        }
    }, [product]);

    const changeProductHandler = (sku) => {
        const selected = product.items.find((item) => {
            return item.product.sku == sku
        });
        setCurrentProduct(selected.product)
    }

    return (
        <div className="product-sidebar">
            {isMobile && !!is_on_order_only && days_to_delivery === 0 && (
                <div className="storeOrderTextboxMobile">
                    <p className="storeOrderHoverText">
                        {' '}
                        <T id="product.orderInTheStoreText"/>
                    </p>
                </div>
            )}

            <div className="product-sidebar__sku">
                <span className="product-sidebar__kod">
                  <T id="product.code"/>:
                </span>
                {' '}
                <div className="product-sidebar__code" itemProp="sku"> {sku} </div>
            </div>


            {product.__typename === 'GroupedProduct' && (
                <div className="product-sidebar__grouped-product">
                    <ProductSidebarAddGroupedProduct
                        product={product}
                        currentSku={currentProduct.sku}
                        onChangeProduct={changeProductHandler.bind(this)}
                    />
                </div>
            )}

            {
                currentProduct.prices.length > 0 && <div className='sidebar-price'>
                    <PriceBlock product={currentProduct} shortLabel={false}/>
                </div>
            }
            <PriceInfo product={currentProduct}/>
            {currentProduct.__typename === 'SimpleProduct' &&
                <ProductSidebarAddSimpleProduct product={currentProduct}/>}

            {currentProduct.__typename === 'GroupedProduct' && <ProductSidebarGroupedPromos product={currentProduct}/>}

            <ProductSidebarShortDescription product={product} setTab={setTab}/>

            <ProductSidebarDelivery product={currentProduct}/>

            {guarantees && <ProductSidebarWarranty data={guarantees} swatch={swatch_attribute_images}/>}

            <ProductSidebarClub product={currentProduct}/>

            <ProductSidebarQuestions sku={sku} name={name}/>

            {/*{isHR && <ProductSidebarHandsfreeDelivery />}*/}

            {isHR && <ProductSidebarPhoneOrder/>}

            {waste && isHR && (
                <div className="product-sidebar__waste">
                    <T id="product.waste"/>{' '}
                    <Link to="/kontakt-i-usluge/ee-otpad" className="product-sidebar__waste-link">
                        <T id="product.here"/>
                    </Link>
                </div>
            )}
        </div>
    );
};

ProductSidebar.propTypes = {
    product: PropTypes.object,
    setTab: PropTypes.func,
};

export default ProductSidebar;
