import {T} from '@deity-io/falcon-i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AddProductInput from '../../UI/AddProductInput/AddProductInput';
import AddToCartButton from '../AddToCartButton/AddToCartButton';
import './ProductSidebarAddSimpleProduct.scss';
import BaseComponent from "./BaseComponent";
import {useHistory} from "react-router-dom";
import {useUserContext} from "../../../context/UserContext";

const ProductSidebarAddSimpleProduct = ({product: {sku = null, ...rest}}) => {
    const kitchenVendorIds = ['601586', '3613', '27293', '16922', '183812', '027293'];
    const [baseComponentActive, setBaseComponentActive] = useState(false);
    const {is_on_order_only, is_saleable, days_to_delivery, name, vendor_id, advanced_product_inventory, uid} = rest;
    const history = useHistory();
    const {user} = useUserContext();
    const [quantity, setQuantity] = useState(advanced_product_inventory.min_sale_qty)

    useEffect(() => {
        setQuantity(rest.advanced_product_inventory.min_sale_qty)
    },[advanced_product_inventory])

    function closebaseComponent() {
        setBaseComponentActive(false);
    }

    const subscribeToAlert = (uid) => {
        const encodedUID = atob(uid);
        const redirectPath = `/productalert/add/${encodedUID}${history.location.pathname}`;

        // Have to redirect here so that we get a clean history
        if (!user) {
            history.push(`/customer/login?referrer=${redirectPath}`);
            return;
        }
        history.push(redirectPath);
    };

    // Copied logic over from app/design/frontend/Emmezeta/blank/Magento_Catalog/templates/product/view/addtocart.phtml
    if (is_on_order_only && days_to_delivery === 0) {
        return (
            <>
                {kitchenVendorIds.includes(vendor_id.toString()) && (
                    <p className="base-component-text"><T id="product.baseComponentText"/> <span onClick={() => {
                        setBaseComponentActive(true)
                    }} className="base-component-text-pink"><T id="product.baseComponentTextPink"/></span></p>
                )}
                <div className="sidebar-add-product">
                    <button className="sidebar-add-product__unavailable" disabled>
                        <T id="product.onlyInStore"/>
                    </button>
                </div>
                {baseComponentActive && (
                    <BaseComponent
                        closeComponent={closebaseComponent}
                        vendor_id={vendor_id.toString()}
                    />
                )}
            </>
        );
    }

    if (!is_saleable) {
        return (
            <div className="sidebar-add-product unavailable">
                <button className="sidebar-add-product__unavailableWeb" disabled>
                    <T id="product.notWebshop"/>
                </button>
                <div className="sidebar-alert">
                    <a className="sidebar-alert__text" onClick={() => subscribeToAlert(uid)}>
                        <T id="product.notice"/>
                    </a>
                </div>
            </div>
        );
    }

    return (
        <>
            {advanced_product_inventory && advanced_product_inventory.enable_qty_increments === '0' &&
                <div className="sidebar-add-product">
                    <AddProductInput
                        minSaleQty={advanced_product_inventory.min_sale_qty}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        advancedInventory={advanced_product_inventory}
                    />
                    <div className="sidebar-add-product__submit">
                        <AddToCartButton sku={sku} quantity={quantity} name={name} product={rest}/>
                    </div>
                </div>
            }
            {advanced_product_inventory && advanced_product_inventory.enable_qty_increments === '1' &&
                <div className="sidebar-add-product-decimal-quantity">
                    <AddProductInput
                        minSaleQty={advanced_product_inventory.min_sale_qty}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        advancedInventory={advanced_product_inventory}
                        prices={rest.prices}
                    />
                    <div className="sidebar-add-product-decimal-quantity__submitDecimalQuantity">
                        <AddToCartButton sku={sku} quantity={quantity} name={name} product={rest}/>
                    </div>
                </div>
            }
        </>
    );
};

ProductSidebarAddSimpleProduct.propTypes = {
    product: PropTypes.object,
};

export default ProductSidebarAddSimpleProduct;
