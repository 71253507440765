import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {T} from '@deity-io/falcon-i18n';
import location from '../../../assets/img/location.svg';

import downArrow from '../../../assets/img/down_arrow.svg';
import email from '../../../assets/img/email.svg';
import phone from '../../../assets/img/phone.svg';
import './ProductSidebarDeliveryAccordion.scss';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';
import Availablity from "../Availablity/Availablity";
import {useLazyQuery} from "react-apollo";
import {AVAILABILITY_QUERY} from "../../../queries/product";
import freePickup from "../../../assets/img/free_pickup.svg";

const phoneSupportNumber = readConfig(productionConfiguration, 'phoneSupportNumber');
const phoneSupportWorkHours = readConfig(productionConfiguration, 'phoneSupportWorkHours');
const phoneSupportWorkHoursWeekend = readConfig(productionConfiguration, 'phoneSupportWorkHoursWeekend');


const ProductSidebarDeliveryAccordion = ({product}) => {
  const [active, setIsActive] = useState(false);
  const {__typename, canonical_url, sku, name} = product
  const [getAvailablity, {loading, data}] = useLazyQuery(AVAILABILITY_QUERY, {
    onCompleted: (data) => {
      setAvailability(data.storeAvailability.filter(({available}) => parseInt(available, 2) === 1));
    }
  });

  const [availability, setAvailability] = useState([]);

  useEffect(() => {
    if (__typename == 'SimpleProduct') {
      getAvailablity(
          {variables: {sku: sku}}
      )
    }
  }, [sku])
  const toggleAccordion = () => {
    setIsActive(prev => !prev);
  };

  if (availability.length == 0) {
    return null;
  }
  return (
      <>
        <div className="delivery-options">
          <img src={freePickup} alt="" className="delivery-options__icon"/>
          <div>
            <T id="product.onlineOrder"/>
            <span className="delivery-options__free">
              <T id="product.onlineOrderFree"/>
          </span>
            <T id="product.onlineOrder2"/>
          </div>
        </div>
        <div className="delivery-options">
          <div className="delivery-accordion">
            <div
                className="delivery-accordion__tab"
                onClick={toggleAccordion}
            >
              <img src={location} alt="" className="delivery-options__icon"/>
              <div>
                <T id="product.availabilityInStore"/>
              </div>
              <img
                  className={`delivery-accordion__tab-arrow delivery-accordion__tab-arrow_${active}`}
                  src={downArrow}
                  alt=""
              />
            </div>
            <div className={`delivery-accordion__content delivery-accordion__content_${active}`}>
              <Availablity availbility={availability}/>

              <div className="delivery-accordion__details">
                <div>
                  <T id="product.queryAboutAvailability"/>
                </div>
                <div className="delivery-accordion__details-row">
                  <img src={email} alt="delivery-form-contact" className="delivery-accordion__details-row-icon"/>
                  <Link
                      className="delivery-accordion__details-link"
                      to={`/emmezeta-pitanja-o-proizvodu?productSku=${sku}&productName=${name}`}
                  >
                    <T id="product.supportViaForm"/>
                  </Link>
                </div>
                <div className="delivery-accordion__details-row">
                  <img src={phone} alt="delivery-phone-info" className="delivery-accordion__details-row-icon"/>
                  <div>
                    <a href={`tel:${phoneSupportNumber}`}>{phoneSupportNumber}</a>
                    <p>{phoneSupportWorkHours}</p>
                    {phoneSupportWorkHoursWeekend && (
                        <p>{phoneSupportWorkHoursWeekend}</p>
                    )}
                    <p>
                      <T id="product.phoneSupportDetails"/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default ProductSidebarDeliveryAccordion;
