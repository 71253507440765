import React from 'react';
import {T} from '@deity-io/falcon-i18n';
import PropTypes from 'prop-types';
import moment from 'moment';
import business from 'moment-business';
import Holidays from 'date-holidays';
import delivery from '../../../assets/img/delivery.svg';
import freePickup from '../../../assets/img/free_pickup.svg';
import ProductSidebarDeliveryAccordion from '../ProductSidebarDeliveryAccordion/ProductSidebarDeliveryAccordion';
import readConfig from '../../../utils/readClientConfiguration';
import appConfiguration from '../../../../config/default.json';
import './ProductSidebarDelivery.scss';
import {isHR, isSI} from '../../../utils/isHr';


// Function with logic for calculating date including holidays.
function calculateDaysIncludingHolidays(calculateTodayDate, daysToDelivery) {
    // After calculating how much holidays we got, we sum it to our days to delivery and add it on current date
    let day = moment().add(1, 'day');
    let holidays = 0;
    for (let i = 0; i < daysToDelivery; i++) {
        if (hd.isHoliday(day) !== false) {
            holidays += 1;
        }
        day = business.addWeekDays(day, 1);
    }
    let dayIncludingHolidays = business.addWeekDays(calculateTodayDate(), holidays + daysToDelivery);
    // Checking if day with included holidays and delivery days is on holiday
    while (hd.isHoliday(dayIncludingHolidays) !== false) {
        dayIncludingHolidays = business.addWeekDays(dayIncludingHolidays, 1);
    }
    return dayIncludingHolidays;
}

const ProductSidebarDelivery = ({product}) => {
    const countryCode = readConfig(appConfiguration, 'countryCode');

    const {__typename, days_to_delivery_range, canonical_url, sku, name, is_saleable} = product;

    if(!days_to_delivery_range){
        return null
    }
    const date_from = new Date(days_to_delivery_range.from * 1000);
    const date_to = new Date(days_to_delivery_range.to * 1000);

    const options = {day: '2-digit', month: '2-digit'};

    const pickupEnabled = isSI ? false : true;
    let showPickup = pickupEnabled;
    if (!is_saleable) {
        // showPickup = is_saleable;
    }
    return (
        <div className="sidebar-delivery">
            {!is_saleable ? null : <div className="delivery-options colored">
                {/*<div><DeliverySVG  title="DEV Icon" className="icon"/></div>*/}
                <img src={delivery} alt="" className="delivery-options__icon"/>
                <div>
                    <T id="product.standardDeliveryTime"/>
                    <span style={{fontWeight: 'bold'}}>
            <T
                id="product.standardDeliveryTime2"
                fromDays={date_from.toLocaleDateString(countryCode, options)}
                toDays={date_to.toLocaleDateString(countryCode, options)}
            />
          </span>
                    {isHR && <T id="product.standardDeliveryTime3"/>}
                </div>
            </div>
            }
            {showPickup &&
                <ProductSidebarDeliveryAccordion product={product} />
            }
        </div>
    );
};

ProductSidebarDelivery.propTypes = {
  product: PropTypes.object,
};

export default ProductSidebarDelivery;
