import gql from "graphql-tag";

export const AVAILABILITY_QUERY = gql`
    query StoreAvailability($sku: String!) {
        storeAvailability(sku: $sku) {
            available
            deliveryFrom
            isExposed
            itemID
            siteID
            siteName
            webSiteCode
        }
    }
`;