import { T } from '@deity-io/falcon-i18n';
import React from 'react';
import { hasValidGroupedClearance, hasValidGroupedPromo } from '../../../utils/promoAndClearanceValidity';
import '../../UI/ProductPrices/ProductPrices.scss';
import ProductPromo from '../../UI/ProductPromo/ProductPromo';
import './ProductSidebarGroupedPromos.scss';

const ProductSidebarGroupedPromos = ({ product }) => {
  const { items } = product || {};

  // Check if any items available, if not - don't do anything
  if (!items.length) {
    return <></>;
  }

  const filtered = items.filter(x => x.product);
  const prices = filtered.flatMap(x => x.product.prices);
  const isLoyalty = prices.find(x => x.price_type === 'simple_loyalty');

  const isPrimary = hasValidGroupedPromo(product);
  const isClearance = hasValidGroupedClearance(product);

  if (!isLoyalty && !isPrimary && !isClearance) {
    return null;
  }

  return (
    <div className="grouped-promos">
      <p className="grouped-promos__text">
        <T id="productItem.groupedProducts" />
      </p>

      <div className="grouped-promos__promo">
        {isLoyalty && (
          <div className="product-price__price-wrap">
            <ProductPromo isGrouped type="club" promoText={<T id="productItem.groupedLoyalty" />} />
          </div>
        )}

        {isPrimary && (
          <div className="product-price__price-wrap">
            <ProductPromo isGrouped type="primary" promoText={<T id="productItem.groupedPromo" />} />
          </div>
        )}

        {isClearance && (
          <div className="product-price__price-wrap">
            <ProductPromo isGrouped type="promo" promoText={<T id="productItem.groupedClearance" />} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSidebarGroupedPromos;
