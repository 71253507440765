import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './ProductSidebarWarranty.scss';

const ProductSidebarWarranty = ({ data, swatch }) => {
  return (
    <>
    <div className="sidebar-warranty">
      {data.map(
        (item, index) =>
          item.guarantee_img_url && (
            <div className="sidebar-warranty__content" key={index}>
              <img loading="lazy" src={item.guarantee_img_url} alt="guarantee" />
              <div className="sidebar-warranty__description">{item.guarantee_description}</div>
              <div className="sidebar-warranty__arrow" />
            </div>
          ),
      )}
    </div>
    {swatch && swatch.length ?
      <div className='sidebar-warranty'>
        {swatch.map((item, index) => (
          <div className='sidebar-warranty__content' key={index}>
            <img loading='lazy' id={item.code} src={item.img} alt='guarantee' />
          </div>
        ))}
      </div> : ( <></> )
    }
    </>
  );
};

ProductSidebarWarranty.propTypes = {
  data: PropTypes.array,
};

export default memo(ProductSidebarWarranty);
