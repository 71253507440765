import React, {memo, useContext} from 'react';
import {I18nContext, T} from '@deity-io/falcon-i18n';
import PropTypes from 'prop-types';

import TextButton from '../../UI/TextButton/TextButton';

import './ProductSidebarShortDescription.scss';
import Labels from "../Labels/Labels";

const ProductSidebarShortDescription = ({product, setTab}) => {
    const showMore = () => {
        setTab('description');
        // setTimeout(() => window && window.scrollTo({ top: 750, behavior: 'smooth' }), 0);
        setTimeout(() => window && window.document.getElementById("productTabs").scrollIntoView({behavior: "smooth"}), 0);
    };
    const {t} = useContext(I18nContext);

    const {short_description} = product;

    const productCardAttachment = product.attachments.find((f) => f.label == 'product_card')
    const productEnergyLabelAttachment = product.attachments.find((f) => f.label == 'energy_label')

    return (
        <div className="sidebar-description">
            <Labels type={'product'} slot={'TR'} product={product}>
                <li style={{display:"flex", flexDirection:'column'}}>
                    {productEnergyLabelAttachment && <span
                        className='link'
                        onClick={() => window.open(productEnergyLabelAttachment.link ? productEnergyLabelAttachment.link :  productEnergyLabelAttachment.frontend_url)}
                    >{t('energy_label')}</span>}
                    {productCardAttachment && <span
                        className='link'
                        onClick={() => window.open(productCardAttachment.link ? productCardAttachment.link :  productCardAttachment.frontend_url)}
                    >{t('product_card')}</span>}
                </li>
            </Labels>



            {short_description && <div dangerouslySetInnerHTML={{__html: short_description.html}}/>}
            <TextButton className="sidebar-description__show-more" onClick={showMore}>
                <T id="product.showMore"/>
            </TextButton>
        </div>
    );
};

ProductSidebarShortDescription.propTypes = {
    data: PropTypes.object,
    setTab: PropTypes.func,
};

export default memo(ProductSidebarShortDescription);
